<template>
  <div>
    <b-row class="m-1">
      <b-col
        cols="6"
        class="d-flex"
      >
        <h2 class="mb-1 text-primary">
          - {{ title }}
        </h2>
      </b-col>
      <b-col
        cols="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-button
          id="add-button"
          class=""
          variant="primary"
          :to="{ name: 'apps-labtest-create', params: { case_id: case_id, type: type } }"
        >
          {{ $t(RESOURCES_ELEMENT_NAME + ".actions.create") }}
        </b-button>
      </b-col>
    </b-row>
    <b-tabs content-class="mt-3">
      <b-tab
        v-for="item in followTypeList"
        :key="item.id"
        :title="item.value"
        :active="item.key == 'ADDITIONAL'"
      >
        <Lab-tests-list
          :type="type"
          :showheader="false"
          :followtype="item.key"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BCardBody, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from '@/views/case/labtests/storeModule'
import LabTestsList from './list.vue'

export default {

  components: {
    BCardBody,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTabs,
    BTab,
    LabTestsList,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      patient_id: undefined,
      case_id: undefined,
      case: undefined,
      followTypeList: [],
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'labtests'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-mh-v-${this.RESOURCES_ELEMENT_NAME}`
    this.patient_id = router.currentRoute.params.patient_id
    this.case_id = router.currentRoute.params.case_id

    this.fetchFollowTypes()

    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetchFollowTypes() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_key_value`, { type: 'destination_order' }).then(response => {
        const keys = ['PATIENT', 'SOURCE', 'FOLLOWUP', 'FIRST_DATE']
        this.followTypeList = response.data.items.filter(element => !keys.includes(element.key))
      }).catch(error => {
        if (error.response.status === 404) {
          console.info(error)
          return undefined
        }
      })
    },
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
