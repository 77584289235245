<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-body>
        <b-row>
          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="6"
          >
            <!--
          <h3 class="mb-1 text-primary text-uppercase">
            <b>{{$t('case.section.lab_tests')}}</b>
          </h3>
        -->
          </b-col>
        </b-row>
        <b-row>
          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="12"
          >
            <Lab-tests-list
              :title="$t('labtests.section.employee') + patientName"
              type="PATIENT"
            />
            <hr>
            <Lab-tests-list
              :title="$t('labtests.section.source') + patientName"
              type="SOURCE"
            />
            <hr>
            <Lab-tests-tabs-list
              :title="$t('labtests.section.followup') + patientName"
              type="FOLLOWUP"
            />
          </b-col>
        </b-row>

        <b-row class="d-flex justify-content-end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :to="{ name: origin }"
          >
            {{ $t('actions.back') }}
          </b-button>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from '@/views/case/labtests/storeModule'
import LabTestsList from './list.vue'
import LabTestsTabsList from './tabsList.vue'

export default {

  components: {
    BCardBody,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    LabTestsList,
    LabTestsTabsList,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      origin: undefined,
      case_id: undefined,
      patientName: '',
    }
  },
  mounted() {
    this.RESOURCES_ELEMENT_NAME = 'labtest'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-mh-v-${this.RESOURCES_ELEMENT_NAME}`
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    this.origin = router.currentRoute.params.origin
    this.case_id = router.currentRoute.params.case_id
    this.fetchCase()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    router() {
      return router
    },
    getPatientName(case0) {
      if (case0 && case0.patient) {
        const { patient } = case0
        console.info()

        this.patientName = `: ${patient.first_name}`
        if (patient.second_name) {
          this.patientName += ` ${patient.second_name}`
        }
        if (patient.first_surname) {
          this.patientName += ` ${patient.first_surname}`
        }
        if (patient.second_surname) {
          this.patientName += ` ${patient.second_surname}`
        }
      } else {
        this.patientName = ''
      }
    },
    fetchCase() {
      console.info('fetchCase:', this.case_id)
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCase`, { id: this.case_id })
        .then(response => {
          const medicalCase = response.data
          this.getPatientName(medicalCase)
        })
        .catch(error => {
          console.info(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
