import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetch_key_value(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/type_value/${type}`, {
            params: { deep: 1 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_case(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFile(ctx, { case_id, type, fileData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/digital_file/digital_file_record/digital_file_exam/${case_id}/${type}`, fileData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMedicalDateTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/follow_type', {
            params: { deep: 1 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTestResults(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/lab_test_result', {
            params: { deep: 1 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUnitsOfMeasure(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/units_of_measure', {
            params: { deep: 1 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLabs(ctx, { companyType, query }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', {
            params: {
              perPage: 500, page: 1, companyType, only_active: true, query,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_lab_tests(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/labtest', {
            params: params.queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/labtest/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCaseDate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/labtest/case_date/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCase(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDocuments(ctx, { prst_uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/digital_file/digital_file_record/record/${prst_uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add_element(ctx, elementData) {
      console.info('Data:', elementData)

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', elementData.file)
        delete elementData.file
        formData.append('data', JSON.stringify(elementData))

        axios
          .post('/api/v1/cases/labtest', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_element(ctx, elementData) {
      console.info('Data:', elementData)

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', elementData.file)
        delete elementData.file
        formData.append('data', JSON.stringify(elementData))
        axios
          .put(`/api/v1/cases/labtest/${elementData.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLabTest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/cases/labtest/${id}`)
          .then(response => {
            console.log("Lab test deleted successfully:", response);
            resolve(response);
          })
          .catch(error => {
            console.error("Error deleting lab test:", error);
            reject(error);
          });
      });
    },
    fetchRoles(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/auth/role', { params: { } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    fetch_services(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/service', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
